
import { gsap } from 'gsap/all'

export const disclaimers = {
  areDisclaimersOpen: false,

  el: {
    disclaimer: {
      privacyPolicy: document.querySelector('.disclaimer--privacy-policy'),
      termsOfService: document.querySelector('.disclaimer--terms-of-service'),
    },
    disclaimers: document.querySelector('.disclaimers'),
    footer: document.querySelector('.footer'),
    link: {
      privacyPolicy: document.querySelector('.footer__footnote-link--privacy-policy'),
      termsOfService: document.querySelector('.footer__footnote-link--terms-of-service'),
    },
  },

  isPrivacyPolicyOpen: false,
  isTermsOfServiceOpen: false,

  init(): void {
    if (!this.el.disclaimers) return

    this.el.link.privacyPolicy.addEventListener('click', () => {
      if (!this.areDisclaimersOpen) {
        this.areDisclaimersOpen = true
        this.isPrivacyPolicyOpen = true
        this.openDisclaimer(this.el.disclaimer.privacyPolicy)
      } else {
        if (this.isTermsOfServiceOpen) {
          this.isPrivacyPolicyOpen = true
          this.isTermsOfServiceOpen = false
          this.switchDisclaimer(this.el.disclaimer.termsOfService, this.el.disclaimer.privacyPolicy)
        } else {
          this.areDisclaimersOpen = false
          this.isPrivacyPolicyOpen = false
          this.closeDisclaimer(this.el.disclaimer.privacyPolicy)
        }
      }
    })

    this.el.link.termsOfService.addEventListener('click', () => {
      if (!this.areDisclaimersOpen) {
        this.areDisclaimersOpen = true
        this.isTermsOfServiceOpen = true
        this.openDisclaimer(this.el.disclaimer.termsOfService)
      } else {
        if (this.isPrivacyPolicyOpen) {
          this.isPrivacyPolicyOpen = false
          this.isTermsOfServiceOpen = true
          this.switchDisclaimer(this.el.disclaimer.privacyPolicy, this.el.disclaimer.termsOfService)
        } else {
          this.areDisclaimersOpen = false
          this.isTermsOfServiceOpen = false
          this.closeDisclaimer(this.el.disclaimer.termsOfService)
        }
      }
    })
  },

  closeDisclaimer(target): void {
    gsap
      .timeline()
      .to(target, {
        clearProps: 'all',
        duration: 0.456,
        ease: 'power3.out',
        opacity: 0,
      }, 0)
      .to(this.el.disclaimers, {
        clearProps: 'all',
        duration: 0.765,
        ease: 'power3.inOut',
        height: 0,
      }, 0)
      .set([this.el.disclaimer.privacyPolicy, this.el.disclaimer.termsOfService], {
        clearProps: 'all',
      }, 0.765)
  },

  openDisclaimer(target): void {
    gsap
      .timeline()
      .set(target, {
        height: 'auto',
      }, 0)
      .to(target, {
        duration: 1,
        ease: 'power3.out',
        opacity: 1,
      }, 0.765)
      .to(this.el.disclaimers, {
        duration: 0.765,
        ease: 'power3.inOut',
        height: 'auto',
      }, 0.01)
      .to(window, {
        duration: 0.987,
        ease: 'power3.inOut',
        scrollTo: this.el.disclaimers,
      }, 0.654)
  },

  switchDisclaimer(targetFrom, targetTo): void {
    const heightFrom = targetFrom.getBoundingClientRect().height
    gsap.set(targetTo, { height: 'auto' })
    const heightTo = targetTo.getBoundingClientRect().height
    gsap.set(targetTo, { clearProps: 'all' })

    gsap
      .timeline()
      .fromTo(targetFrom, {
        opacity: 1,
      }, {
        duration: 0.321,
        onComplete: () => gsap.set(targetFrom, { height: 0 }),
        opacity: 0,
      }, 0)
      .fromTo(this.el.disclaimers, {
        height: heightFrom,
      }, {
        duration: 0.765,
        ease: 'power3.inOut',
        height: heightTo,
        onComplete: () => gsap.set(this.el.disclaimers, { height: 'auto' }),
      }, 0.1)
      .fromTo(targetTo, {
        opacity: 0,
      }, {
        duration: 0.321,
        onStart: () => gsap.set(targetTo, { height: 'auto' }),
        opacity: 1,
      }, 0.321)
      
  },

  toggleDisclaimer({ action, target }): void {
    if (action === 'open') {
      this.el.disclaimers.classList.add('disclaimers--active')
      target.classList.add('disclaimer--active')
    }

    gsap.set(this.el.disclaimers, { height: action === 'open' ? 0 : 'auto' })
    gsap.to(this.el.disclaimers, {
      clearProps: 'all',
      delay: 0.01,
      duration: 0.543,
      ease: 'power3.inOut',
      height: action === 'open' ? 'auto' : 0,
      onComplete: () => {
        action === 'close' && target.classList.remove('disclaimer--active')
        target === this.el.disclaimer.privacyPolicy && (this.isPrivacyPolicyOpen = action === 'open' ? true : false)
        target === this.el.disclaimer.termsOfService && (this.isTermsOfServiceOpen = action === 'open' ? true : false)
        this.areDisclaimersOpen = action === 'open' ? true : false
      },
    })
  },

  toggleDisclaimerOld({ action, target }): void {
    action === 'open' && target.classList.add('disclaimer--active')
    
    gsap.set(target, { height: action === 'open' ? 0 : 'auto' })
    gsap.to(target, {
      clearProps: 'all',
      delay: 0.01,
      duration: 0.543,
      ease: 'power3.inOut',
      height: action === 'open' ? 'auto' : 0,
      onComplete: () => {
        action === 'close' && target.classList.remove('disclaimer--active')
        target === this.el.disclaimer.privacyPolicy && (this.isPrivacyPolicyOpen = action === 'open' ? true : false)
        target === this.el.disclaimer.termsOfService && (this.isTermsOfServiceOpen = action === 'open' ? true : false)
      },
    })

    if (action === 'open') {
      gsap.to(window, {
        delay: 0.456,
        duration: 1,
        ease: 'power3.inOut',
        scrollTo: target,
      })
    }
  },
}
