
import { color, gsapBreakpoints } from 'helpers'
import { fireworks } from '../components/firework'
import { gsap, ScrollTrigger } from 'gsap/all'

export const footer = {
  el: {
    footer: document.querySelector('.footer'),
    links: document.querySelectorAll('.footer__footnote-link'),
    text: {
      column: document.querySelectorAll('.footer__text-column'),
      miako: document.querySelectorAll('.footer__text-tagline i'),
      motto: document.querySelector('.footer__text-motto'),
      tagline: document.querySelector<any>('.footer__text-tagline'),
    },
  },

  init(): void {
    if (!this.el.footer) return

    this.animateText()
    this.handleHovers()
  },

  animateText(): void {
    const commonProps = { duration: 1.5, ease: 'power3.out' }

    gsap.matchMedia().add(gsapBreakpoints, (context) => {
      const colorFrom = '#111'
      const colorTo = [color.purple, color.red, color.orangeRed, color.yellow, color.green]
      const is = context.conditions

      gsap.set(this.el.text.column, {
        opacity: 0,
        scrollTrigger: {
          onEnter: () => gsap.timeline()
            .set(this.el.text.miako, { color: colorFrom, overwrite: true })
            .set(this.el.text.column, { opacity: 1 })
            .fromTo([this.el.text.motto, this.el.text.tagline], { opacity: 0 }, { opacity: 1, stagger: 0.1, ...commonProps })
            .fromTo(this.el.text.miako, { color: colorFrom }, { color: (self) => colorTo[self], stagger: 0.1, ...commonProps }, 0.6)
            .set(this.el.text.tagline, { onComplete: () => {
              if (ScrollTrigger.isInViewport(this.el.text.tagline)) {
                const x = this.el.text.tagline.firstElementChild.getBoundingClientRect().width
                fireworks.shoot(undefined, { parent: this.el.text.tagline, position: 'absolute', x, y: 32 })
              }
            }}, 1.25),
          onLeaveBack: () => gsap.timeline()
            .fromTo([this.el.text.tagline, this.el.text.motto], { opacity: 1 }, { opacity: 0, stagger: 0.1, ...commonProps })
            .to(this.el.text.miako, { color: colorFrom, overwrite: true, ...commonProps }),
          // start: is.desktop ? '0 80%' : '0 90%',
          start: !is.desktop ? '0 85%' : '0 90%',
          trigger: this.el.text.column,
        },
      })
    })
  },

  handleHovers(): void {
    const colors = Object.values(color)
    let colorIndex = 0

    this.el.links.forEach((link) => {
      link.addEventListener('mouseover', () => {
        gsap.to(link, { color: colors[colorIndex], duration: 0.321, overwrite: true })
        colorIndex = colorIndex === colors.length - 1 ? 0 : colorIndex + 1
      })

      link.addEventListener('mouseout', () => {
        gsap.to(link, { clearProps: 'all', color: '#111', duration: 0.321 })
      })
    })
  }
}
