
import { simultaneousKeyPress } from 'helpers'

export const overlayGrid = {
  el: {
    grid: null,
    button: null,
  },

  init(): void {
    this.renderGrid()
    this.el.button = document.querySelector<HTMLButtonElement>('.overlay-grid__button')
    this.el.grid = document.querySelector<HTMLElement>('.overlay-grid')
    this.handleButton()
    this.handleKeys()
  },

  handleButton(): void {
    this.el.button.addEventListener('click', () => {
      this.el.button.classList.toggle('overlay-grid__button--active')
      this.el.grid.classList.toggle('overlay-grid--active')
    })
  },

  handleKeys(): void {
    document.addEventListener('keydown', (event) => {
      simultaneousKeyPress.down(event, ['g', 'r'], () => {
        this.el.button.classList.toggle('overlay-grid__button--active')
        this.el.grid.classList.toggle('overlay-grid--active')
      })
    })
    document.addEventListener('keyup', simultaneousKeyPress.up.bind(this))
  },

  renderGrid(): void {
    document.body.insertAdjacentHTML('beforeend', `
      <div class="overlay-grid">
        <div class="container overlay-grid__container">
          <div class="grid">
            ${Array.from({ length: 12 }, () => '<div class="overlay-grid__column"></div>').join('')}
          </div>
        </div>
        <button type="button" class="overlay-grid__button">||||||</button>
      </div>
    `)
  },
}
