
import { debounce } from 'throttle-debounce'
import { SplitText } from 'gsap/all'

export const breakpoint = {
  phone: {
    xs: 0,
    s: 321,
    m: 480,
  },
  tablet: 768,
  desktop: {
    xs: 1024,
    s: 1366,
    m: 1600,
    l: 1920,
    xl: 2560,
  },
}

export const color = {
  blue: '#40a4d8',
  blueGreen: '#33beb8',
  green: '#b2c225',
  orangeRed: '#f6621f',
  orangeYellow: '#f9a228',
  pink: '#ee657a',
  purple: '#a363d9',
  red: '#db3838',
  yellow: '#fecc2f',
}

export const esbuildReload = () => {
  new EventSource('https://localhost:9000/esbuild').addEventListener('change', e => {
    const { added, removed, updated } = JSON.parse(e.data)
  
    if (!added.length && !removed.length && updated.length === 1) {
      for (const link of document.getElementsByTagName("link") as any) {
        const url = new URL(link.href)
  
        if (url.host === location.host && url.pathname === updated[0]) {
          const next = link.cloneNode()
          next.href = updated[0] + '?' + Math.random().toString(36).slice(2)
          next.onload = () => link.remove()
          link.parentNode.insertBefore(next, link.nextSibling)
          return
        }
      }
    }
  
    location.reload()
  })
}

export const gsapBreakpoints = {
  desktop: `(min-width: ${breakpoint.desktop.xs}px) `,
  tablet: `(min-width: ${breakpoint.tablet}px) and (max-width: ${breakpoint.desktop.xs - 1}px)`,
  mobile: `(max-width: ${breakpoint.tablet - 1}px)`,
}

export const isProduction = process.env.NODE_ENV === 'production'

export let isVerticalResize = false

// export const killAnimations = () => {
//   // Not using this, but worth looking into in the future - kinda preventing ScrollTriggers pile up on window resize, but ocassionally causing gsap errors
//   window.addEventListener('resize', () => {
//     pageScroll.paused(true)
//     ScrollTrigger.killAll()
//     setTimeout(() => {
//       pageScroll.paused(false)
//     }, 123)
//   })
// }

export const mediaLabel = () => {
  document.body.insertAdjacentHTML('beforeend', '<div class="media-label"></div>')
}

export const pageRestore = () => {
  // Page restore for mobile iOS - without this back/forward buttons won't hide loader covers.
  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      window.location.reload()
    }
  })
}

export const pressedKeys = new Set()

export const simultaneousKeyPress = {
  down(event: KeyboardEvent, keys: Array<string>, callback: any): void {
    const key = event.key.toLowerCase()
    pressedKeys.add(key)

    if (keys.every((keyToDetect) => pressedKeys.has(keyToDetect))) {
      callback()
      pressedKeys.clear()
    }
  },

  up(event: KeyboardEvent): void {
    const key = event.key.toLowerCase()
    pressedKeys.delete(key)
  },
}

export const splitText = ({ afterInit = () => {}, afterResize = () => {}, isAnyResize = false, target = undefined }) => {
  if (!target) return

  let textLines = undefined
  
  const resetText = () => textLines.revert()
  const setText = () => textLines = new SplitText(target, { lineThreshold: 1, tag: 'span', type: 'lines' })

  document.fonts.ready.then(setText).then(afterInit)

  window.addEventListener('resize', debounce(30, () => {
    // TODO - Figure out Safari spring-reload issue, this kinda works but not really
    // if (!isVerticalResize || (isVerticalResize && window.scrollY <= 10)) {
    if (isAnyResize || !isVerticalResize) {
      resetText()
    }
  }))

  window.addEventListener('resize', debounce(120, () => {
    // if (!isVerticalResize || (isVerticalResize && window.scrollY <= 10)) {
    if (isAnyResize || !isVerticalResize) {
      setText()
      setTimeout(() => {
        afterResize()
      }, 1)
    }
  }))
}

export const verticalResizeCheck = () => {
  let lastWindowHeight = window.innerHeight
  let lastWindowWidth = window.innerWidth

  window.addEventListener('resize', () => {
    const currentHeight = window.innerHeight
    const currentWidth = window.innerWidth
    isVerticalResize = currentHeight !== lastWindowHeight && currentWidth === lastWindowWidth

    lastWindowHeight = currentHeight
    lastWindowWidth = currentWidth
  })
}
