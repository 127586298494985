
import { gsap, ScrollTrigger } from 'gsap/all'
import { breakpoint, splitText } from 'helpers'

export const header = {
  el: {
    background: {
      rainbow: document.querySelector('.header__background'),
      stripes: document.querySelectorAll('.header__background svg g'),
      stripesInner: document.querySelectorAll('.header__background svg g path'),
    },
    header: document.querySelector('.header'),
    heading: {
      column: document.querySelector('.header__heading-column'),
      mission: document.querySelector('.header__heading--mission'),
      tagline: document.querySelector('.header__heading--tagline'),
    },
  },

  init(): void {
    if (!this.el.header) return

    splitText({
      afterInit: () => {
        this.adjustMargin()
        this.animateBackground()
        this.injectExtraTags()
        this.pinHeader()
      },
      afterResize: () => {
        this.adjustMargin()
        ScrollTrigger.getById('header').kill()
        this.injectExtraTags()
        this.pinHeader()
      },
      // isAnyResize: true,
      target: header.el.heading.mission,
    })

  },

  adjustMargin(): void {
    const rainbowHeight = header.el.background.rainbow.getBoundingClientRect().height
    const purpleHeight = header.el.background.rainbow.querySelector('g:last-child').getBoundingClientRect().height
    const scaleModifier = window.innerWidth > 1000 ? 1920 : window.innerWidth > 767 ? 1200 : 800
    
    gsap.set(header.el.background.rainbow, { scaleX: window.innerWidth < 1920 ? scaleModifier / window.innerWidth : 1.03 })
    gsap.set(header.el.heading.column, { marginBottom: rainbowHeight - purpleHeight * 1.1 })
  },

  animateBackground(): void {
    const textLines = this.el.heading.mission.querySelectorAll('span')

    if (!textLines.length) return

    gsap
      .timeline()
      .set(this.el.background.stripes, { scaleY: 0 })
      .set(textLines, { opacity: 0 })
      .to(this.el.background.stripes, { duration: (index) => 0.6 * (index * 0.1 + 1), ease: 'power4.inOut', scaleY: 1, stagger: 0.05 }, 0.7)
      .call(() => {
        this.el.background.stripesInner.forEach((stripe) => {
          gsap.to(stripe, {
            delay: gsap.utils.random(-4, 0),
            duration: gsap.utils.random(4, 5),
            ease: 'power2.inOut',
            repeat: -1,
            skewX: gsap.utils.random(-1.5, 1.5),
            yoyo: true,
          })
        })
      }, null, 1.2)
      .to(textLines, { duration: (index) => 1.5 * (index * 0.3 + 1), ease: 'power3.out', opacity: 1, stagger: 0.1 }, 1.55)

    gsap.fromTo(this.el.background.rainbow.firstElementChild, {
      scaleX: window.innerWidth <= breakpoint.tablet ? 1.6 : 1.3,
    }, {
      ease: 'none',
      scaleX: 1,
      scrollTrigger: {
        end: '+=123%',
        scrub: 1,
        start: '0 0',
        trigger: this.el.header,
      },
    })
  },

  injectExtraTags(): void {
    this.el.heading.mission.querySelectorAll('span').forEach((span) => {
      span.innerHTML = `<strong>${span.innerHTML}</strong>`
    })
  },

  pinHeader(): void {
    gsap
      .timeline({
        defaults: {
          ease: 'none',
        },
        scrollTrigger: {
          // TODO - Fix pin spacing with ScrollTrigger refreshes or MatchMedia reverts (?)
          end: '+=112%',
          id: 'header',
          pin: this.el.header,
          // pinSpacing: true, // This breaks all the following ScrollTriggers on the page
          scrub: 1,
          start: '0 0',
          trigger: this.el.header,
        },
      })
      // TODO - As above
      .to([this.el.background.rainbow, this.el.heading.column], { duration: 3, y: window.innerWidth <= breakpoint.tablet ? 0 : -50 }, 0)
      .to(this.el.heading.mission.querySelectorAll('strong'), { duration: 1, opacity: 0, stagger: 0.2 }, 0)
      .to(this.el.heading.tagline, { duration: 1.23, opacity: 1 }, 1)
    
  },
}
