
import { gsap, ScrollToPlugin, ScrollTrigger, SplitText } from 'gsap/all'

import { a11y } from 'utility/a11y'
import { editableContent } from 'utility/editable-content'
import { esbuildReload, isProduction, mediaLabel, pageRestore, verticalResizeCheck } from './utility/helpers'
import { overlayGrid } from 'utility/overlay-grid'
import { smoothScroll } from 'utility/smooth-scroll'

// import { fireworks } from './components/firework'

import { disclaimers } from './layout/disclaimers'
import { footer } from './layout/footer'
import { header } from './layout/header'
import { support } from './layout/support'

import { DetailsSection } from './sections/details-section'
import { InfoSection } from './sections/info-section'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, SplitText)

const el = {
  detailsSections: document.querySelectorAll<HTMLElement>('.section--details'),
  infoSections: document.querySelectorAll<HTMLElement>('.section--info'),
}

const app = {
  init(): void {  
    a11y.init()
    !isProduction && editableContent.init()
    !isProduction && esbuildReload()
    !isProduction && mediaLabel()
    !isProduction && overlayGrid.init()
    pageRestore()
    smoothScroll.init()
    verticalResizeCheck()

    // fireworks.init()
  
    disclaimers.init()
    footer.init()
    header.init()
    support.init()

    el.detailsSections.forEach((section) => DetailsSection(section))
    el.infoSections.forEach((section) => InfoSection(section))

    app.run()
  },
  
  run(): void {
    setTimeout(() => {
      document.body.classList.add('body--loaded')
      window.scrollTo(0, 0)
    }, 345)
  },
}

document.addEventListener('DOMContentLoaded', app.init)
