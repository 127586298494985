
export const a11y = {
  init(): void {
    window.addEventListener('keydown', a11y.handleTab)
  },

  handleMouseDown(): void {
    document.body.classList.remove('body--a11y')
    window.addEventListener('keydown', a11y.handleTab)
    window.removeEventListener('mousedown', a11y.handleMouseDown)
  },

  handleTab(event): void {
    if (event.key === 'Tab') {
      document.body.classList.add('body--a11y')
      window.addEventListener('mousedown', a11y.handleMouseDown)
      window.removeEventListener('keydown', a11y.handleTab)
    }
  },
}
