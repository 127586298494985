
import { gsap } from 'gsap/all'
import { gsapBreakpoints, splitText } from 'helpers'

export const DetailsSection = (target: HTMLElement) => {
  const el = {
    image: {
      container: target.querySelector('.section__image-container'),
      image: target.querySelector('.section__image'),
    },
    text: {
      container: target.querySelector('.section__text-container'),
      heading: target.querySelector('.section__heading'),
      text: target.querySelector('.section__text'),
    },
    section: target,
  }

  let animation = {
    image: undefined,
    text: undefined,
  }

  const fn = {
    init(): void {
      splitText({
        afterInit: () => {
          fn.animateImage(el.section.classList[2].replace('section--', ''))
          fn.animateText()
        },
        afterResize: () => {
          // animation.image.revert()
          animation.text.revert()
          // fn.animateImage(el.section.classList[2].replace('section--', ''))
          fn.animateText()
        },
        target: [el.text.heading, el.text.text.firstElementChild],
      })
    },
    
    animateImage(sectionName): void {
      animation.image = gsap.matchMedia().add(gsapBreakpoints, (context) => {
        const is = context.conditions
        const scrollTrigger = {
          end: is.mobile ? '+=50%' : '0% 0%',
          scrub: 1.5,
          start: is.mobile ? '50% 95%' : '0% 100%',
          trigger: el.image.container,
        }

        // gsap.set(el.image.container, { overflow: 'visible' })

        switch (sectionName) {
          case 'eco-friendly':
            gsap.fromTo(el.image.image, {
              x: is.mobile ? '-69%' : '-25%',
              y: is.mobile ? '59%' : '15%',
            }, {
              scrollTrigger,
              x: 0,
              y: 0,
            })
            break
          case 'fun-stuff':
            gsap.fromTo(el.image.image, {
              x: '20%',
            }, {
              // scrollTrigger,
              scrollTrigger: {
                end: '100% 0%',
                scrub: 1.5,
                start: '0% 100%',
                trigger: el.image.container,
              },
              x: '-40%',
            })
            break
          case 'give-money-support-passion':
            gsap.fromTo(el.image.image, {
              y: is.mobile ? '-59%' : '-15%',
            }, {
              scrollTrigger,
              y: 0,
            })
            break
          case 'make-money-save-money':
            gsap.fromTo(el.image.image, {
              x: is.mobile ? '83%' : '20%',
            }, {
              scrollTrigger,
              x: 0,
            })
            break
          case 'our-mission':
            gsap.fromTo(el.image.image, {
              rotation: is.mobile ? 10 : 3.5,
              x: is.mobile ? '73%' : '30%',
              y: is.mobile ? '10%' : '4%',
            }, {
              rotation: 0,
              scrollTrigger,
              x: 0,
              y: 0,
            })
            break
          case 'our-vision':
            gsap.fromTo(el.image.image, {
              x: is.mobile ? '-78%' : '-30%',
              y: is.mobile ? '40%' : '20%',
            }, {
              scrollTrigger,
              x: 0,
              y: 0,
            })
            break
          case 'social-responsibility':
            gsap.fromTo(el.image.image, {
              y: is.mobile ? '69%' : '-15%',
            }, {
              scrollTrigger,
              y: is.mobile ? 0 : '5%',
            })
            break
          case 'trust-and-simplicity':
            gsap.fromTo(el.image.image, {
              rotation: is.mobile ? -17 : -4,
              x: is.mobile ? '-25%' : '-10%',
              y: is.mobile ? '-65%' : '-20%',
            }, {
              rotation: 0,
              scrollTrigger,
              x: 0,
              y: 0,
            })
            break
        }
      })
    }, 

    animateText(): void {      
      animation.text = gsap.matchMedia().add(gsapBreakpoints, (context) => {
        const is = context.conditions
        const textLines = el.section.querySelectorAll('.section__heading span, .section__text span')

        if (!textLines.length) return

        gsap.fromTo(textLines, {
          opacity: 0,
        }, {
          ease: 'none',
          opacity: 1,
          scrollTrigger: {
            end: !is.mobile ? '+=30%' : '+=35%',
            scrub: 1,
            start: '0 90%',
            trigger: el.text.container,
          },
          stagger: 0.1,
        })
  
        gsap.fromTo(el.text.container, {
          y: is.desktop ? '17vh' : is.tablet ? '10vh' : 0,
        }, {
          scrollTrigger: {
            end: '100% 0',
            scrub: 1,
            start: '0 100%',
            trigger: el.section,
          },
          y: 0,
        })
      })
    },
  }

  fn.init()
}
