
import { gsap } from 'gsap/all'
import { gsapBreakpoints, splitText } from 'helpers'

export const InfoSection = (target: HTMLElement) => {
  const el = {
    heading: target.querySelector('.section__heading'),
    section: target,
  }

  let mm = undefined

  const fn = {
    init(): void {
      splitText({
        afterInit: fn.animateText,
        afterResize: () => {
          mm.revert()
          fn.animateText()
        },
        target: el.heading,
      })
    },

    animateText(): void {
      mm = gsap.matchMedia().add(gsapBreakpoints, (context) => {
        const is = context.conditions
        const textLines = el.section.querySelectorAll('.section__heading span')

        if (!textLines.length) return
  
        gsap.fromTo(textLines, {
          opacity: 0,
          // textAlign: !is.mobile ? 'left' : 'center',
        }, {
          ease: 'none',
          opacity: 1,
          scrollTrigger: {
            end: !is.mobile ? '+=30%' : '+=35%',
            scrub: 1,
            start: !is.mobile ? '0 90%' : '0 80%',
            trigger: el.section,
          },
          stagger: 0.1,
        })
      })
    },  
  }

  fn.init()
}
