
import { color } from 'helpers'
import { gsap } from 'gsap/all'

export const fireworks = {
  clickEnabled: false,
  colorsArray: Object.values(color),
  fireworksAmount: 44,

  init(): void {
    if (fireworks.clickEnabled) {
      document.querySelector('.body').addEventListener('click', (event) => fireworks.shoot(event, {}))
    }
  },

  renderBubble(target): void {
    const bubble = document.createElement('div')
    const container = document.createElement('div')

    bubble.classList.add('firework__bubble')
    container.classList.add('firework__bubble-container')
    target.appendChild(container)
    container.appendChild(bubble)

    gsap.set(container, {
      rotation: gsap.utils.random(0, 360),
    })

    const tween = gsap.fromTo(bubble, {
      background: gsap.utils.random(fireworks.colorsArray),
      scale: gsap.utils.random(5, 30) * 0.1,
      x: gsap.utils.random(3, 21),
      y: 0,
    }, {
      duration: gsap.utils.random(0.93, 2.37) * 1.2,
      ease: 'power4.out',
      onComplete: () => {
        bubble.remove()
        container.remove()
        tween.kill()
        if (target.children.length === 0) target.remove()
      },
      scale: 0,
      x: gsap.utils.random(77, 211),
      y: gsap.utils.random(-21, 21),
    })
  },

  renderRing(target): void {
    const ring = document.createElement('div')
    const size = gsap.utils.random(123, 234)

    ring.classList.add('firework__ring')
    target.appendChild(ring)

    const tween = gsap.to(ring, {
      duration: 0.77,
      ease: 'power3.out',
      height: size,
      onComplete: () => {
        ring.remove()
        tween.kill()
      },
      opacity: 0,
      width: size,
    })
  },

  shoot(event, { delay = 0, parent = document.querySelector('body'), position = 'fixed', x = 0, y = 0 }): void {
    const left = x !== 0 ? x : event.touches ? event.touches[0].clientX : event.clientX
    const top = y !== 0 ? y : event.touches ? event.touches[0].clientY : event.clientY

    const firework = document.createElement('div')
    firework.classList.add('firework')
    position === 'absolute' && firework.classList.add('firework--absolute')
    parent.appendChild(firework)
    gsap.set(firework, {
      delay,
      left,
      top,
      onComplete: () => {
        // fireworks.renderRing(firework)
        for (let _ of Array(fireworks.fireworksAmount)) {
          fireworks.renderBubble(firework)
        }
      },
    })
  },
}
