
import Lenis from '@studio-freight/lenis'
import { gsap, ScrollTrigger } from 'gsap/all'

export let lenisScroll = undefined

export const smoothScroll = {
  init(): void {
    lenisScroll = new Lenis({
      lerp: 0.1,
    })

    function raf(time) {
      lenisScroll.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)

    // this.integrateScrollTrigger()
  },

  // Optional, not needed.
  // If used sometime it breaks down smooth scroll on first page load, but doesn't affect scroll performance much (except for pinned elements, sometimes they're laggy).
  // Edit: This messes up the footer animation

  integrateScrollTrigger(): void {
    lenisScroll.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time) => {
      lenisScroll.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)
  },
}
