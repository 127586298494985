
import { simultaneousKeyPress } from 'helpers'

export const editableContent = {
  enabled: false,
  selectors: 'h1, h2, h3, h4, h5, h6, p, li',

  init(): void {
    document.addEventListener('keydown', (event) => {
      simultaneousKeyPress.down(event, ['c', 'e'], () => {
        !this.enabled ? this.enableEditableContent() : this.disableEditableContent()
        this.enabled = !this.enabled
      })
    })
    document.addEventListener('keyup', simultaneousKeyPress.up)
  },

  disableEditableContent(): void {
    document.querySelectorAll(this.selectors).forEach((element) => {
      element.setAttribute('contenteditable', 'false')
    })
  },

  enableEditableContent(): void {
    document.querySelectorAll(this.selectors).forEach((element) => {
      element.setAttribute('contenteditable', 'true')
    })
    document.querySelectorAll('a').forEach((element) => {
      element.setAttribute('contenteditable', 'false')
    })
  },
}
