
import { debounce } from 'throttle-debounce'
import { gsap } from 'gsap/all'
import { isVerticalResize } from 'helpers'

export const support = {
  el: {
    layout: document.querySelector('.layout'),
    support: document.querySelector('.support'),
  },

  init(): void {
    if (!this.el.layout || !this.el.support) return

    this.adjustMargin()

    window.addEventListener('resize', debounce(120, () => {
      if (!isVerticalResize) {
        support.adjustMargin()
      }
    }))
  },

  adjustMargin(): void {
    gsap.set(this.el.layout, {
      marginBottom: this.el.support.getBoundingClientRect().height,
    })
  },
}
